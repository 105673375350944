import { Box, Typography } from "@mui/material"
import React from "react"

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { error: null }
        this.prevPath = null
    }

    static getDerivedStateFromError(error) {
        return { error: true, errorInfo: error }
    }

    componentDidMount() {
        this.prevPath = window.location.pathname
    }

    componentDidUpdate() {
        if (window.location.pathname !== this.prevPath)
            this.setState({ error: null })
        this.prevPath = window.location.pathname
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.error("Error boundary Message:  ", error, errorInfo)
    }

    render() {
        const { error } = this.state
        const { children } = this.props

        if (error) {
            // You can render any custom fallback UI
            return (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        mt: 1,
                    }}
                >
                    <Typography
                        sx={{
                            display: "inline",
                            fontSize: 30,
                            fontWeight: "bold",
                            color: "red",
                        }}
                    >
                        Error.
                    </Typography>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            mt: 0.5,
                            gap: 1,
                        }}
                    >
                        <Typography
                            sx={{
                                display: "inline",
                                fontSize: 30,
                                color: "#9f9b94",
                                mb: 0.5,
                            }}
                        >
                            {error
                                ? `${error?.response?.data?.error?.message}` ||
                                  `${error?.message}` ||
                                  "Network Error!"
                                : ""}
                        </Typography>
                        <Typography
                            sx={{
                                display: "inline",
                                fontSize: 30,
                            }}
                        >
                            That is all we know.
                        </Typography>
                    </Box>
                </Box>
            )
        }

        return children
    }
}

export default ErrorBoundary
