import * as React from "react"
import {
    AppBar as MuiAppBar,
    Toolbar,
    IconButton,
    Typography,
    Avatar,
    Menu,
    Divider,
    Drawer as MuiDrawer,
    List,
    Button,
    useTheme,
} from "@mui/material"

import MenuIcon from "@mui/icons-material/Menu"
import { Box, styled } from "@mui/system"
import {
    Api,
    CardGiftcard,
    CurrencyExchange,
    Discount,
    FormatListNumbered,
    Hail,
    Logout,
    ManageAccounts,
    MenuOpen,
    People,
    PeopleOutline,
    Receipt,
    ReceiptLong,
    Settings,
} from "@mui/icons-material"
import { useLocation, Outlet, useNavigate } from "react-router-dom"
import jwtDecode from "jwt-decode"
import RideLogo from "../Assets/ride_logo.png"
import Link from "../Component/link.jsx"
import Page from "../Component/breadCrumbs.jsx"
import ErrorBoundaryWrapper from "../Component/errorBoundary.jsx"
import {
    CompanyInfo,
    CompanyToggle,
} from "../Pages/SystemUsers/companyList.jsx"
import {
    BASE_URL,
    BMS_BASE_URL,
    RP_ASSETS_URL,
    SSO_API_BASE_URL,
    SSO_ASSETS_URL,
    SSO_BASE_URL,
    SSO_VERSION,
    VERSION,
} from "../Utils/config.js"
import useUserPermissions from "../Hooks/useUserPermissions.js"
import AuthContext from "../Context/auth_provider.jsx"
import useBreakPoints from "../Hooks/useBreakPoints.js"
import InstallPrompt from "../Component/installPrompt.jsx"
import useFetchEntities from "../Hooks/useFetchEntity.js"
import fallback from "../Assets/companyy.svg"

const drawerWidth = "20%"
const drawerWidthXL = "16%"

function LogoFallback({ open, projectTheme }) {
    return (
        <img
            src={fallback}
            alt="logo placeholder"
            style={{
                height: open ? 90 : 36,
                width: open ? 90 : 36,
                transition: (() =>
                    projectTheme.transitions.create(["width", "height"], {
                        easing: projectTheme.transitions.easing.sharp,
                        duration:
                            projectTheme.transitions.duration.enteringScreen,
                    }))(),
            }}
        />
    )
}

const openedMixin = (theme) => ({
    width: drawerWidth,
    [theme.breakpoints.up("lg")]: {
        width: drawerWidthXL,
    },
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
})

const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(5)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(7)} + 1px)`,
    },
})

// const DrawerHeader = styled("div")(({ theme }) => ({
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "flex-end",
//     ...theme.mixins.toolbar,
// }))

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        [theme.breakpoints.up("md")]: {
            marginLeft: drawerWidth,
            // width: `calc(100% - ${drawerWidth}%)`,
            width: "80%",
            [theme.breakpoints.up("lg")]: {
                width: "84%",
            },
        },
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}))

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}))

export default function Main({ prompt, setState, promptToInstall }) {
    const location = useLocation()
    const projectTheme = useTheme()

    const [anchorEl, setAnchorEl] = React.useState(null)
    const openMenu = Boolean(anchorEl)

    const navigate = useNavigate()
    const { toggle, companyList } = React.useContext(CompanyToggle)
    const { selectedCompany } = React.useContext(CompanyInfo)

    const { auth } = React.useContext(AuthContext)
    const decoded = jwtDecode(auth?.id_token)

    // EVENT HANDLERS & HELPERS
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    const handleSignOut = () => {
        window.location.href = `${SSO_BASE_URL}/rpLogout?post_logout_redirect_uri=${BMS_BASE_URL}/login`
    }

    const [open, setOpen] = React.useState(true)
    const [mobileOpen, setMobileOpen] = React.useState(false)
    const { xs, md, sm } = useBreakPoints()

    const [noLogo, setNoLogo] = React.useState(false)

    const { account_type: accountType } = selectedCompany || {}

    const {
        apiKeysList,
        programsPage,
        usersPage,
        transactionsPage,
        systemUsersPage,
        invoicePage,
        configPage,
        rolesPage,
        seeBalance,
        vouchersList,
        discountsList,
        receiptsList,
    } = useUserPermissions()

    const { data } = useFetchEntities(`accounts`, { enabled: seeBalance })

    const drawer = (
        <Box
            sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                background: "#FFFFFF 10% 0% no-repeat padding-box",
            }}
        >
            <Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        height: "76px",
                        background: "#FAFAFA 0% 0% no-repeat padding-box ",
                        boxShadow: "0px 3px 10px #00000029",
                    }}
                >
                    <Typography
                        noWrap
                        component="div"
                        sx={{
                            ml: 1.3,
                            fontSize: "23px",
                            fontWeight: "bolder",
                            textTransform: "uppercase",
                        }}
                    >
                        {selectedCompany?.name}
                    </Typography>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => setOpen(!open)}
                        edge="start"
                        size="large"
                        sx={{
                            marginRight: "6px",
                        }}
                    >
                        {open ? <MenuOpen /> : <MenuIcon />}
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        py: open ? 2 : 1,
                        mt: !open && 1,
                    }}
                >
                    {noLogo ? (
                        <LogoFallback open={open} projectTheme={projectTheme} />
                    ) : (
                        <img
                            src={`${BASE_URL}/${VERSION}/${RP_ASSETS_URL}/company_logo/${selectedCompany?.logo}`}
                            onError={() => {
                                setNoLogo(true)
                            }}
                            alt="company logo"
                            style={{
                                height: open ? 90 : 36,
                                width: open ? 90 : 36,
                                transition: (() =>
                                    projectTheme.transitions.create(
                                        ["width", "height"],
                                        {
                                            easing: projectTheme.transitions
                                                .easing.sharp,
                                            duration:
                                                projectTheme.transitions
                                                    .duration.enteringScreen,
                                        }
                                    ))(),
                            }}
                        />
                    )}
                </Box>
                <Divider
                    sx={{
                        width: "100%",
                        height: "12px",
                        color: "#DBDBDB",
                    }}
                />
                <List>
                    {usersPage && (
                        <Link
                            runFn={() => setMobileOpen(false)}
                            to="/users"
                            icon={<People />}
                            label="People"
                            open={open}
                        />
                    )}
                    {programsPage && (
                        <Link
                            runFn={() => setMobileOpen(false)}
                            to="/programs"
                            icon={<Hail />}
                            label="Programs"
                            open={open}
                        />
                    )}
                    {vouchersList && (
                        <Link
                            runFn={() => setMobileOpen(false)}
                            to="/vouchers"
                            icon={<CardGiftcard />}
                            label="Vouchers"
                            open={open}
                        />
                    )}
                    {discountsList && (
                        <Link
                            runFn={() => setMobileOpen(false)}
                            to="/discounts"
                            icon={<Discount />}
                            label="Discounts"
                            open={open}
                        />
                    )}
                    {transactionsPage && (
                        <Link
                            runFn={() => setMobileOpen(false)}
                            to="/transactions"
                            icon={<CurrencyExchange />}
                            label="Transactions"
                            open={open}
                        />
                    )}
                    {invoicePage && (
                        <Link
                            runFn={() => setMobileOpen(false)}
                            to={
                                accountType === "POSTPAID"
                                    ? "/invoice"
                                    : "/topups"
                            }
                            icon={<Receipt />}
                            label="Billing"
                            open={open}
                        />
                    )}
                    {receiptsList && (
                        <Link
                            runFn={() => setMobileOpen(false)}
                            to="/receipts"
                            icon={<ReceiptLong />}
                            label="Receipts"
                            open={open}
                        />
                    )}
                    {systemUsersPage && (
                        <Link
                            runFn={() => setMobileOpen(false)}
                            to="/system-users"
                            icon={<PeopleOutline />}
                            label="System Users"
                            open={open}
                        />
                    )}
                    {rolesPage && (
                        <Link
                            runFn={() => setMobileOpen(false)}
                            to="/roles"
                            icon={<ManageAccounts />}
                            label="Roles"
                            open={open}
                        />
                    )}
                    {configPage && (
                        <Link
                            runFn={() => setMobileOpen(false)}
                            to="/config"
                            icon={<Settings />}
                            label="Configuration"
                            open={open}
                        />
                    )}
                    {apiKeysList && (
                        <Link
                            runFn={() => setMobileOpen(false)}
                            to="/api-keys"
                            icon={<Api />}
                            label="API Keys"
                            open={open}
                        />
                    )}
                </List>
            </Box>
            <Box sx={{ flex: 1 }} />
            <Box>
                <Divider
                    sx={{
                        width: "100%",
                        height: "12px",
                        color: "#DBDBDB",
                    }}
                />
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        justifyContent: "center",
                        py: open ? 2 : 1,
                        background: "#FAFAFA 0% 0% no-repeat padding-box ",
                    }}
                >
                    <img
                        src={RideLogo}
                        alt="ridePlus logo"
                        style={{
                            height: 40,
                            width: 40,
                            transition: (() =>
                                projectTheme.transitions.create(
                                    ["width", "height"],
                                    {
                                        easing: projectTheme.transitions.easing
                                            .sharp,
                                        duration:
                                            projectTheme.transitions.duration
                                                .enteringScreen,
                                    }
                                ))(),
                        }}
                    />
                    {open && (
                        <Typography variant="caption">
                            RIDE Plus Corporate version 2.0.5
                        </Typography>
                    )}
                </Box>
            </Box>
        </Box>
    )

    return (
        <Box
            sx={{
                display: "flex",
            }}
        >
            <AppBar
                position="fixed"
                color="secondary"
                open={open}
                sx={{
                    boxShadow: "0px 2px 5px #00000029",
                    background: " #FFFFFF 0% 0% no-repeat padding-box",
                    py: 0.7,
                }}
            >
                <InstallPrompt
                    prompt={prompt}
                    setState={setState}
                    promptToInstall={promptToInstall}
                />
                <Toolbar sx={{ display: "flex", alignItems: "center" }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => setOpen(!open)}
                        edge="start"
                        sx={{
                            marginRight: "36px",
                            display: { xs: "none", md: "block" },
                            ...(open && { display: "none" }),
                        }}
                    >
                        {open ? null : <MenuIcon />}
                    </IconButton>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => setMobileOpen(!mobileOpen)}
                        edge="start"
                        sx={{
                            marginRight: "6px",
                            display: { xs: "block", md: "none" },
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Page location={location} />
                    <Box flex={1} />
                    {seeBalance && data && (
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                p: ".5em",
                                backgroundColor: "common.main",
                                transition: projectTheme.transitions.create(
                                    ["width"],
                                    {
                                        easing: projectTheme.transitions.easing
                                            .easeIn,
                                        duration: "2s",
                                    }
                                ),
                            }}
                        >
                            <span
                                style={{
                                    fontSize: xs && !sm ? "10px" : "12px",
                                }}
                            >
                                Balance
                            </span>
                            <strong
                                style={{
                                    fontSize: xs && !sm ? "14px" : "16px",
                                }}
                            >
                                {accountType === "POSTPAID"
                                    ? `-${Math.round(
                                          data?.data?.data?.[0]?.balance
                                      ).toLocaleString()}`
                                    : `${Math.round(
                                          data?.data?.data?.[0]?.balance
                                      ).toLocaleString()}`}{" "}
                                ETB
                            </strong>
                        </Box>
                    )}
                    <IconButton
                        size="large"
                        aria-label="display more actions"
                        edge="end"
                        color="inherit"
                        onClick={handleClick}
                    >
                        <Avatar
                            sx={{
                                height: "40px",
                                width: "40px",
                            }}
                            variant="circular"
                            src={`${SSO_API_BASE_URL}/${SSO_VERSION}/${SSO_ASSETS_URL}/${decoded?.picture}`}
                        />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={openMenu}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: "visible",
                                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                mt: 1.5,
                                "&:before": {
                                    content: '""',
                                    display: "block",
                                    position: "absolute",
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: "background.paper",
                                    transform: "translateY(-50%) rotate(45deg)",
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{
                            horizontal: "right",
                            vertical: "top",
                        }}
                        anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                mt: 2,
                                mb: 3,
                                px: 2,
                                width: 250,
                            }}
                        >
                            <Avatar
                                sx={{
                                    width: 70,
                                    height: 70,
                                    mb: 2,
                                }}
                                variant="circular"
                                src={`${SSO_API_BASE_URL}/${SSO_VERSION}/${SSO_ASSETS_URL}/${decoded?.picture}`}
                            />
                            <Typography
                                sx={{
                                    fontWeight: "bold",
                                    maxWidth: 300,
                                }}
                                noWrap
                            >
                                {`${decoded?.first_name} ${decoded?.middle_name}`}
                            </Typography>
                            <Typography
                                variant="caption"
                                sx={{
                                    color: "gray",
                                    maxWidth: 300,
                                }}
                                noWrap
                            >
                                {decoded?.email}
                            </Typography>
                        </Box>
                        <Divider />
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-around",
                                alignItems: "center",
                                gap: "1em",
                                mt: 2,
                                mb: 1,
                                px: 6,
                            }}
                        >
                            {companyList?.length > 1 && (
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="common"
                                    size="small"
                                    onClick={() => {
                                        toggle()
                                        navigate("/")
                                    }}
                                    startIcon={
                                        <FormatListNumbered fontSize="small" />
                                    }
                                >
                                    Companies
                                </Button>
                            )}
                            <Button
                                fullWidth
                                variant="contained"
                                color="common"
                                size="small"
                                onClick={handleSignOut}
                                startIcon={<Logout fontSize="small" />}
                            >
                                Sign Out
                            </Button>
                        </Box>
                    </Menu>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    display: {
                        xs: "none",
                        md: "block",
                    },
                }}
                variant="permanent"
                open={open}
            >
                {drawer}
            </Drawer>
            <MuiDrawer
                sx={{
                    display: { xs: "block", md: "none" },
                }}
                PaperProps={{
                    sx: {
                        width: 240,
                        height: "100vh",
                    },
                }}
                variant="temporary"
                open={mobileOpen}
                onClose={() => setMobileOpen(false)}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
            >
                {drawer}
            </MuiDrawer>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    mx: xs && !md ? "auto" : "1%",
                    mt: "91px",
                    maxWidth: {
                        lg: open ? "82%" : "93%",
                        md: open ? "78%" : "93%",
                        xs: "100%",
                    },
                    backgroundColor: "#ffffff",
                }}
            >
                <ErrorBoundaryWrapper>
                    <Outlet />
                </ErrorBoundaryWrapper>
            </Box>
        </Box>
    )
}
