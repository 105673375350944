import {
    ExpandLess,
    ExpandMore,
    Receipt,
    ReceiptLong,
} from "@mui/icons-material"
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material"
import { DateTime } from "luxon"
import MaterialReactTable from "material-react-table"
import { useLocation, useNavigate } from "react-router-dom"
import React, { useCallback, useMemo, useState } from "react"

import useTableProps from "../../Hooks/useTableProps.js"
import useTableState from "../../Hooks/useTableState.js"
import ViewInvoice from "../../Component/viewInvoice.jsx"
import useBreakPoints from "../../Hooks/useBreakPoints.js"
import useUserPermissions from "../../Hooks/useUserPermissions.js"

const invoicePayment = [
    {
        accessorKey: "created_by",
        header: "Payment Initiator",
        enableColumnFilter: false,
        enableSorting: false,
    },
    {
        accessorKey: "amount",
        header: "Amount",
        enableColumnFilter: false,
        enableSorting: false,
    },
    {
        accessorKey: "created_at",
        header: "Payment Date",
        enableColumnFilter: false,
        enableSorting: false,
        Cell: ({ cell }) => DateTime.fromISO(cell.getValue()).toFormat("ff"),
    },
    {
        accessorKey: "id",
        header: "Approver",
        enableColumnFilter: false,
        enableSorting: false,
    },
    {
        accessorKey: "bank_name",
        header: "Bank Name",
        enableColumnFilter: false,
        enableSorting: false,
    },
    {
        accessorKey: "status",
        header: "Status",
        enableColumnFilter: false,
        enableSorting: false,
        Cell: ({ cell }) => (
            <Button
                sx={{
                    height: "2em",
                    backgroundColor: "lightgray",
                    borderRadius: "1em",
                }}
            >
                {cell.getValue()}
            </Button>
        ),
    },
    {
        accessorKey: "remark",
        header: "Remark",
        enableColumnFilter: false,
        enableSorting: false,
    },
    {
        accessorKey: "reference_number",
        header: "Reference Number",
        enableColumnFilter: false,
        enableSorting: false,
    },
    {
        accessorKey: "updated_at",
        header: "Updated at",
        enableColumnFilter: false,
        enableSorting: false,
        Cell: ({ cell }) => DateTime.fromISO(cell.getValue()).toFormat("ff"),
    },
    {
        accessorKey: "action",
        header: "Action",
        enableSorting: false,
        enableColumnFilter: false,
    },
]

function InvoiceDetail() {
    const { receiptsDetails } = useUserPermissions()
    const [open, setOpen] = useState(false)

    const { md } = useBreakPoints()
    const navigate = useNavigate()

    const { state, onChangeHandlers } = useTableState({
        columns: invoicePayment,
    })

    const { state: invoiceData } = useLocation()

    // INVOICE SUMMARY
    const { top_ups: topUps } = invoiceData || {}
    const actionsColumn = useCallback(
        ({ row }) => (
            <Box
                sx={{
                    display: "flex",
                }}
            >
                {(row?.original?.status === "APPROVED" ||
                    row?.original?.corporate_top_up?.status === "APPROVED") &&
                    receiptsDetails && (
                        <Tooltip title="Get Receipt">
                            <IconButton
                                onClick={() =>
                                    navigate(
                                        `${
                                            row?.original
                                                ?.accounting_transaction_id ||
                                            row?.original?.corporate_top_up
                                                ?.accounting_transaction_id
                                        }`
                                    )
                                }
                            >
                                <ReceiptLong sx={{ color: "primary.main" }} />
                            </IconButton>
                        </Tooltip>
                    )}
            </Box>
        ),
        [navigate, receiptsDetails]
    )
    const colDefs = useMemo(
        () =>
            invoicePayment.map((el) => {
                if (el.accessorKey === "action") {
                    return {
                        ...el,
                        Cell: actionsColumn,
                    }
                }
                return el
            }),
        [actionsColumn]
    )
    const { props } = useTableProps({
        columns: colDefs,
        state: {
            ...state,
        },
    })

    return (
        <Box sx={{ pt: "1em", backgroundColor: "common.main" }}>
            <Accordion
                expanded={open}
                onChange={() => {
                    setOpen(!open)
                }}
                sx={{ mb: "3em" }}
            >
                <AccordionSummary
                    sx={{ boxShadow: 1, backgroundColor: "white", p: "2em" }}
                >
                    <IconButton size="large">
                        {" "}
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <Box sx={{ marginLeft: "1em" }}>
                            <Typography
                                variant="h5"
                                sx={{ fontWeight: "bold" }}
                            >
                                Invoice Detail
                            </Typography>
                            <Typography variant="body1">
                                Expand to see invoice detail and print it
                            </Typography>
                        </Box>
                        <IconButton size="large" color="primary">
                            <Receipt />
                        </IconButton>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <ViewInvoice />
                </AccordionDetails>
            </Accordion>
            {topUps?.length > 0 && (
                <MaterialReactTable
                    data={topUps || []}
                    initialState={{
                        columnPinning: {
                            right: [md ? "action" : null],
                        },
                    }}
                    {...onChangeHandlers}
                    {...props}
                />
            )}
        </Box>
    )
}

export default InvoiceDetail
