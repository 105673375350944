import { Print } from "@mui/icons-material"
import { Avatar, Box, Button, Grid, Typography } from "@mui/material"
import { DateTime } from "luxon"
import React, { useContext, useRef } from "react"
import { useLocation } from "react-router-dom"
import { useReactToPrint } from "react-to-print"
import useBreakPoints from "../Hooks/useBreakPoints.js"
import { CompanyInfo } from "../Pages/SystemUsers/companyList.jsx"
import { formatToTwoDecimalPlaces } from "../Utils/dataFormat.js"
import RideLogo from "../Assets/RIDE_logo.png"

function InvoiceHeader(props) {
    const { issuedDate, startDate, endDate } = props || {}

    return (
        <Grid
            container
            spacing={1}
            sx={{
                backgroundColor: "#effbf1",
                p: "1.5em",
                borderTop: "8px solid black",
            }}
        >
            <Grid item xs={12}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                    }}
                >
                    <Avatar
                        variant="square"
                        sx={{
                            height: 60,
                            width: 120,
                        }}
                        src={RideLogo}
                    />
                </Box>
            </Grid>
            <Grid item xs={8}>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    Hybrid Designs PLC
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    Date
                </Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography>Lebenez Tower 7th Floor, Meskel Flower</Typography>
                <Typography>around Gazebo Square</Typography>
                <Typography>Addis Ababa, Ethiopia</Typography>
            </Grid>
            <Grid item xs={4} sx={{ pl: "1em", fontWeight: "bold" }}>
                <Typography>
                    <strong>Invoice Date:</strong>{" "}
                    {DateTime.fromISO(startDate).toFormat("LLL dd yyyy")} -{" "}
                    {DateTime.fromISO(endDate).toFormat("LLL dd yyyy")}
                </Typography>
                <Typography>
                    <strong>Issued Date:</strong>{" "}
                    {DateTime.fromISO(issuedDate).toFormat("ff")}
                </Typography>
            </Grid>
        </Grid>
    )
}

function BillTo({ company }) {
    const { name, address, phone, email } = company
    return (
        <Grid container spacing={1} sx={{ mt: "1em", p: "1.5em" }}>
            <Grid item xs={12}>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                    Bill To
                </Typography>
            </Grid>
            <Grid item xs={7}>
                <Typography>
                    <strong>Company Name:</strong> {name}
                </Typography>
                <Typography>
                    <strong>Address:</strong> {address}{" "}
                </Typography>
            </Grid>
            <Grid item xs={5}>
                <Typography>
                    <strong>Phone:</strong> {phone}{" "}
                </Typography>
                <Typography>
                    <strong>Email:</strong> {email}{" "}
                </Typography>
            </Grid>
        </Grid>
    )
}

function InvoiceSummary(props) {
    const {
        subtotal,
        discount,
        vat,
        totalVat,
        balance,
        serviceCharge,
        serviceChargeRate,
    } = props

    return (
        <Grid container sx={{ mt: "2em" }}>
            <Grid item xs={6} />
            <Grid item xs={6}>
                <Typography>
                    <strong>SUBTOTAL</strong>{" "}
                    {formatToTwoDecimalPlaces(subtotal)}
                </Typography>
                <Typography>
                    <strong>DISCOUNT</strong> {discount ?? 0}
                </Typography>
                <Typography>
                    <strong>SUBTOTAL LESS DISCOUNT</strong>{" "}
                    {formatToTwoDecimalPlaces(subtotal ?? 0)} ETB
                </Typography>
                <Typography>
                    <strong>Service Caharge Rate</strong>{" "}
                    {serviceChargeRate ?? 0}%
                </Typography>
                <Typography>
                    <strong>TOTAL Service Charge</strong>{" "}
                    {formatToTwoDecimalPlaces(serviceCharge ?? 0)} ETB
                </Typography>
                <Typography>
                    <strong>VAT RATE</strong> {vat ?? 0}%
                </Typography>
                <Typography>
                    <strong>TOTAL VAT</strong>{" "}
                    {formatToTwoDecimalPlaces(totalVat ?? 0)} ETB
                </Typography>
                <Box
                    sx={{
                        color: "#ffe123",
                        p: ".3em",
                        pl: 0,
                    }}
                >
                    <Typography variant="h6">
                        Balance Due: {formatToTwoDecimalPlaces(Number(balance))}
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    )
}

function ViewInvoice() {
    const componentRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        bodyClass: "print",
    })

    const { md } = useBreakPoints()

    const { selectedCompany } = useContext(CompanyInfo)

    const { state } = useLocation()
    // INVOICE SUMMARY
    const {
        subtotal,
        total_discount: discount,
        total_vat: totalVat,
        vat_rate: vat,
        due_balance: balance,
        service_charge_rate: serviceChargeRate,
        total_service_charge: serviceCharge,
    } = state || {}

    // INVOICE HEADER
    const { created_at: issuedDate, from, to } = state || {}

    return (
        <Box sx={{ pt: "4em" }}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    pr: md ? "4em" : ".5em",
                }}
            >
                <Button
                    onClick={handlePrint}
                    variant="contained"
                    startIcon={<Print />}
                    sx={{
                        height: "3em",
                        borderRadius: "1.5em",
                        color: "primary.main",
                        backgroundColor: "#ffe123",
                        "&:hover": {
                            backgroundColor: "#ffe123",
                        },
                    }}
                >
                    <Typography>Print</Typography>
                </Button>
            </Box>
            <Box sx={{ mb: "1em", overflow: "auto" }}>
                <Box ref={componentRef} sx={{ p: "2.5em", m: "auto" }}>
                    <InvoiceHeader
                        issuedDate={issuedDate}
                        startDate={from}
                        endDate={to}
                    />
                    <BillTo company={selectedCompany} />
                    <InvoiceSummary
                        subtotal={subtotal}
                        discount={discount}
                        totalVat={totalVat}
                        vat={vat}
                        balance={balance}
                        serviceCharge={serviceCharge}
                        serviceChargeRate={serviceChargeRate}
                    />
                </Box>
            </Box>
        </Box>
    )
}

export default ViewInvoice
