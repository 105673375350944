import { useContext } from "react"
import { CompanyInfo } from "../Pages/SystemUsers/companyList.jsx"

const useUserPermissions = () => {
    const { userPermissions } = useContext(CompanyInfo)

    const perms = userPermissions?.map((el) => el.name)

    const hasPermission = (permission) =>
        perms?.some((el) => el === permission || el === "manage-all")

    return {
        programsUsersList: hasPermission("list program users"),
        transactionsList: hasPermission("list all transactions"),
        programsTransactions: hasPermission("view programs transaction"),
        usersInvite: hasPermission("invite users"),
        programsCreate: hasPermission("create program"),
        transactionsView: hasPermission("view street pickup details"),
        usersAssign: hasPermission("assign program to users"),
        usersAssignSingle: hasPermission('get user by phone'),
        usersInvitedProgramsUpdate: hasPermission('update corporate program invited users'),
        usersAssignBulk: hasPermission('get users by phone'),
        programsDetail: hasPermission("view programs detail"),
        programsUpdate: hasPermission("update program"),
        usersListInvited: hasPermission("list invited users"),
        programsList: hasPermission("list programs"),
        usersList: hasPermission("list users"),
        usersProgramsUpdate: hasPermission("update assigned programs"),
        configUpdate: hasPermission("update tip config"),
        systemUsersList: hasPermission("get system users"),
        systemUsersRevokeRole: hasPermission("revoke role"),
        systemUsersView: hasPermission(""),
        systemUsersAdd: hasPermission("assign role"),
        invoiceList: hasPermission("list invoices"),
        rolesUpdate: hasPermission("update role"),
        rolesList: hasPermission('get all roles'),
        rolesCreate: hasPermission('create role'),
        rolesDelete: hasPermission('delete role'),
        rolesDetail: hasPermission('get role detail'),
        rolesStatusUpdate: hasPermission("update user's role status"),
        topupsList: hasPermission('get pre paid top ups'),
        seeBalance: hasPermission('get account balance'),
        activitiesList: hasPermission('list corporate activities'),
        getAllPermissions: hasPermission('get all permissions'),
        generateVoucher: hasPermission('voucher'),
        vouchersList: hasPermission('view voucher'),
        vouchersUpdate: hasPermission('update-voucher'),
        voucherUsers: hasPermission('view voucher users'),
        apiKeysList: hasPermission('list all corporate api keys'),
        generateApiKey: hasPermission('create corporate api key'),
        apiKeyDelete: hasPermission('delete corporate api key'),
        generateDiscount: hasPermission('generate discount code'),
        discountsList: hasPermission('get discount all discount codes'),
        discountsUpdate: hasPermission('update-discount'),
        discountUsers: hasPermission('view discount users'),
        receiptsList: hasPermission('get corporate receipts'),
        receiptsDetails: hasPermission('get receipt by transaction ID'),

        //  PAGES
        get programsPage() {
            return (
                this.programsUsersList ||
                this.programsCreate ||
                this.programsDetail ||
                this.programsUpdate
            )
        },
        get invoicePage() {
            return this.invoiceList || this.invoiceView
        },
        get usersPage() {
            return (
                this.usersInvite ||
                this.usersListInvited ||
                this.usersAssign ||
                this.usersProgramsUpdate ||
                this.usersList
            )
        },
        get transactionsPage() {
            return this.transactionsList || this.transactionsView
        },
        get systemUsersPage() {
            return (
                this.systemUsersList ||
                this.systemUsersRevokeRole ||
                this.systemUsersAdd
            )
        },
        get rolesPage() {
            return (
                this.rolesCreate || this.rolesDelete || this.rolesList || this.rolesDetail || this.rolesStatusUpdate || this.rolesUpdate
            )
        },
        get configPage() {
            return this.configUpdate
        },
        get voucherPage() {
            return this.generateVoucher || this.vouchersList || this.vouchersUpdate || this.voucherUsers
        },
        get apiKeyPage() {
            return this.generateApiKey || this.apiKeysList || this.apiKeyDelete
        },
        get discountPage() {
            return this.generateDiscount || this.discountsList || this.discountsUpdate || this.discountUsers
        },
        get receiptPage() {
            return this.receiptsList || this.receiptsDetails
        }
    }
}

export default useUserPermissions
